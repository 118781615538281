<template>
  <div class="qrcode-container">
    <div style="position:absolute;">
      <img id="myimg" style="width: 100vw;height: 100vw;object-fit: cover;overflow: hidden" v-show="false">
      <canvas id="mycanvas" style="width: 100vw;height: 100vw;opacity: 0;overflow: hidden"></canvas>
    </div>
    <div style="position: absolute;width: 100%;height: 100%;display: flex;flex-direction: column;align-items: center">
      <img src="../../../assets/scan_qrcode_logo.png" style="width: 226px;height: auto;margin-top: 116px">
      <div style="padding: 0px 30px;box-sizing: border-box;width: 100%;margin-top: 55px">
        <div class="scan-btn" @click="doScan(0)">
          <img src="../../../assets/scan_qrcode_icon.png" style="width: 20px;height: auto">
          <p class="scan-text">点击扫码加入新课程</p>
        </div>
        <div class="scan-btn"  @click="doScan(1)" style="margin-top: 20px;background-color: #FFA831">
          <img src="../../../assets/scan_qrcode_icon_album.png" style="width: 20px;height: auto">
          <p class="scan-text">扫描相册照片</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {getSignPackageFWH} from "../../../api/keneng";
import {Loading} from 'element-ui'
import jsQR from "jsqr"
import {
  getOauthUrl
} from "../../../utils/store";

export default {
  name: "ScanQRCode",
  mounted() {
  },
  methods: {
    doScan(type) {
      let loadingInstance = Loading.service({
        fullscreen: true,
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.8)'
      })
      let that = this
      //open_id
      let url = window.location.href//this.$route.fullPath
      // let url = getOauthUrl()
      getSignPackageFWH(url).then((res) => {
        const result = res.data
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: result.appId, // 必填，公众号的唯一标识
          timestamp: result.timestamp, // 必填，生成签名的时间戳
          nonceStr: result.nonceStr, // 必填，生成签名的随机串
          signature: result.signature, // 必填，签名
          jsApiList: [
            'scanQRCode',
            'chooseImage'
          ] // 必填，需要使用的JS接口列表
        })
      })
      wx.ready(() => {
        if (type == 0) {
          that.startScanQRCode()
        } else {
          that.firstChooseImage()
        }
        loadingInstance.close()
      })
      wx.error((err) => {
        loadingInstance.close()

        console.log('exception:' +  JSON.stringify(err))
      })
    },
    firstChooseImage() {
      let that = this
      wx.chooseImage({
        count: 1, // 默认9
        sizeType: ['original', 'compressed'], // 可以指定是原图还是压缩图，默认二者都有
        sourceType: ['album', 'camera'], // 可以指定来源是相册还是相机，默认二者都有
        success: function (res) {
          var localIds = res.localIds; // 返回选定照片的本地ID列表，localId可以作为img标签的src属性显示图片
          that.convertLocalImage(localIds)
        }
      });
    },
    convertLocalImage(localIds) {
      let localId = localIds[0].toString()
      let that = this
      wx.getLocalImgData({
        localId: localId, // 图片的localID
        success: function (res) {
          var imageData = res.localData; // localData是图片的base64数据，可以用img标签显示
          that.getImgRealUrl(imageData)
        }
      });
    },
    getImgRealUrl(url) {
      if (url.indexOf('data:image') != 0) {
        url = 'data:image/jpeg;base64,' + url
      }
      let that = this
      var img = document.getElementById("myimg");
      img.src = url;
      img.crossOrigin = '';
      img.onload = function () {
        var mycanvas = document.getElementById("mycanvas");
        var ctx = mycanvas.getContext("2d");
        mycanvas.width = img.width;
        mycanvas.height = img.height;
        ctx.drawImage(img, 0, 0);
        var imageData = ctx.getImageData(0, 0, img.width, img.height);
        const code = jsQR(imageData.data, imageData.width, imageData.height, {
          inversionAttempts: "dontInvert",
        });
        if (code) {
          that.formatScanResult(code.data)
        } else {
          that.$message.error('获取失败～')
        }
      }
    },
    startScanQRCode() {
      let that = this
      wx.scanQRCode({
        needResult: 1,
        scanType: ["qrCode"], // 可以指定扫二维码还是一维码，默认二者都有
        success: (res) => {
          // 支付成功后的回调函数
          let result = res.resultStr
          // let host = 'https://keneng-fwh.learningfirst.cn/'
          if (result) {
            // let pos = result.indexOf(host)
            // if (pos < 0) {
            //   that.$message.error('非法路径～')
            //   return
            // }
            that.formatScanResult(result)
          } else {
            that.$message.error('非法路径～')
          }
        }
      })
    },
    formatScanResult(result) {
      if (result.indexOf('share-collection') >= 0) {
        var merchant_id = ''
        var collection_id = ''

        let u = result.split('?')
        if (typeof (u[1] == 'string')) {
          u = u[1].split('&')
          for (var i in u) {
            var _obj = u[i].split('=')
            if (_obj[0] == 'merchant_id') {
              merchant_id = _obj[1]
            } else if (_obj[0] == 'collection_id') {
              collection_id = _obj[1]
            }
          }
        }
        this.$router.replace({
          path: '/share-collection',
          query: {
            merchant_id: merchant_id,
            collection_id: collection_id
          }
        })
      } else if (result.indexOf('share-album') >= 0) {
        var merchant_id = ''
        var album_id = ''
        let u = result.split('?')
        u = u[1].split('&')
        for (var i in u) {
          var _obj = u[i].split('=')
          if (_obj[0] == 'merchant_id') {
            merchant_id = _obj[1]
          } else if (_obj[0] == 'album_id') {
            album_id = _obj[1]
          }
        }
        this.$router.replace({
          path: '/share-album',
          query: {
            merchant_id: merchant_id,
            album_id: album_id
          }
        })
      }
    }
  }
}
</script>

<style scoped>

.scan-text {
  margin-left: 15px;
  font-size: 18px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 28px;
}

.qrcode-container {
  position: relative;
  width: 100vw;
  height: 100vh;
  background-repeat: no-repeat;
  background-color: transparent;
  background-size: cover;
  background-image: url("../../../assets/scan_qrcode_bg.png");
  display: flex;
  flex-direction: column;
  align-items: center;
}

.scan-btn {
  cursor: pointer;
  width: 100%;
  background: #FCC52D;
  height: 42px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 24px 1px rgba(0, 0, 0, 0.05);
  border-radius: 26px 26px 26px 26px;
}
</style>
